<template>
    <el-dialog title="选择跳转链接" :visible.sync="currenurlVisible" @close="close" class="dial">
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="分销商名称">
                    <el-input v-model="search.name" clearable @change="">
                    </el-input>
                </el-form-item>


                <div style="float: right">
                    <el-button type="primary" @click="loadDistributor()">搜索</el-button>
                </div>
            </el-form>
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="distributorList"
                      highlight-current-row>
                <el-table-column prop="id" label="ID" align="center"></el-table-column>
                <el-table-column prop="name" label="名称" align="center"></el-table-column>
                <el-table-column prop="billName" label="开单名称	" align="center"></el-table-column>
                <el-table-column prop="receiverMobile" label="收件人手机号	" align="center"></el-table-column>
                <el-table-column prop="receiverName" label="收件人姓名	" align="center"></el-table-column>
                <el-table-column prop="supplierLevelName" label="分销商等级名称	" align="center"></el-table-column>
                <el-table-column label="状态" prop="status" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.status === 0">等审核</el-tag>
                        <el-tag type="primary" v-if="scope.row.status === 1">合作中</el-tag>
                        <el-tag type="waring" v-if="scope.row.status === -1">已终止</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status===1" class="point" type="success" @click="chose(scope.row)">选中</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="sortList" v-on:refreshPageSize="refreshPageSize"
                        v-on:refreshPageNum="refreshPageNum"/>
        </div>
    </el-dialog>
</template>
<script>
import {request} from '@/common/request';
import Pagination from '@/components/Pagination';
/* 再此定义JS，采用ES6标准格式 */
export default {
    name: 'Distributor',
    components: {
        Pagination
    },
    props: ['urlVisible'], // 声明自定义属性
    data() {
        return {
            defaultImg: require('../assets/img/zhan.jpeg'),
            distributorList: [],
            distributorSelectId:0,
            sortList:[],
            search: {
                name:""
            },
            stretch: true,
            loading: false,
            queryUrlParams: {
                state: 2,
                page: 1,
                pagesize: 10
            },
            currenurlVisible: false,
            url: '',
            urlList: [],
            positionList: [],
        };
    },
    mounted() {
        this.loadDistributor(1)
    },
    watch: {
        // 监听 addOrUpdateVisible 改变
        urlVisible(oldVal, newVal) {
            this.currenurlVisible = this.urlVisible
        },
    },
    methods: {
        // url选择
        chose(row) {
            console.log("--------------- get distributor id is :" + row)
            this.$emit('listenChoiceDistributor', row);
            this.close()
        },
        close() {
            this.$emit('toggle', false);
        },


        //获取分销商列表
        loadDistributor(ty) {
            request.get('/distributor/list', { ty: ty, state: 2 }).then(ret => {
                if (ret.code === 1) {

                    this.distributorList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryUrlParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryUrlParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryUrlParams.page = val;
            this.loadData();
        },






    }
}
</script>
<style scoped>
/* 在此定义当前页面css */
.name {
    margin-right: 10px;
}

.block .el-col {
    margin-bottom: 20px;
}
.dial >>> .el-dialog {
    width: 80%;
}
.point {
    cursor: pointer;
}
</style>>
